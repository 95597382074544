import * as JWTDecode from 'jwt-decode'

export default function({ app, redirect }) {
  // If the user is not authenticated
  const token: string = app.$apolloHelpers.getToken()
  if (!token) {
    return redirect('/login')
  }

  const rolesCurrentUser = (<any>JWTDecode)(token).roles
  if (
    !rolesCurrentUser ||
    rolesCurrentUser.filter((role) =>
      ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'].includes(role)
    ).length === 0
  ) {
    return redirect('/')
  }
}
