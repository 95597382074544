






import { Vue } from 'nuxt-property-decorator'
import Component from 'nuxt-class-component'

@Component({
  middleware: 'notAuthenticated'
})
export default class Menu extends Vue {}
