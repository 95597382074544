import Vue from 'vue'
import JWTDecode from 'jwt-decode'

Vue.mixin({
  data() {
    return {
      formRules: {
        required: (value) => !!value || 'Required.',
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        }
      }
    }
  },
  computed: {
    rolesCurrentUser() {
      const token = this.$apolloHelpers.getToken()
      if (token) {
        const tokenDecoded: any = JWTDecode(token)
        const rolesCurrentUser = tokenDecoded.roles

        return rolesCurrentUser
      }
      return false
    },
    hasEditorRole() {
      return (
        this.rolesCurrentUser &&
        (this.rolesCurrentUser.includes('ROLE_EDITOR') || this.hasAdminRole)
      )
    },
    hasAdminRole() {
      return (
        this.rolesCurrentUser &&
        (this.rolesCurrentUser.includes('ROLE_ADMIN') || this.hasSuperAdminRole)
      )
    },
    hasSuperAdminRole() {
      return (
        this.rolesCurrentUser &&
        this.rolesCurrentUser.includes('ROLE_SUPER_ADMIN')
      )
    }
  },
  methods: {
    getApiImageUrl(publicPath) {
      return encodeURI(`//${process.env.DOMAIN_API}${publicPath}`)
    }
  }
})
