
































































































import { Vue } from 'nuxt-property-decorator'
import Component from 'nuxt-class-component'
import Logo from '~/components/Logo.vue'
import MenuMyAccount from '~/components/MenuMyAccount.vue'

@Component({
  components: {
    Logo,
    MenuMyAccount
  },
  middleware: 'authenticated'
})
export default class Menu extends Vue {
  isShow: boolean = false
  hasAdminRole: any
  showMenuAccount: boolean = false

  closeMenuMyAccount() {
    this.showMenuAccount = false
  }

  itemListAdminGroup: {
    icon: string
    label: string
    link: string
    color: string
  }[] = [
    {
      icon: 'people',
      label: 'Users',
      link: '/users',
      color: 'red'
    }
  ]

  itemListEditorGroup: {
    icon: string
    label: string
    link: string
    color: string
  }[] = [
    {
      icon: 'present_to_all',
      label: 'Releases',
      link: '/releases',
      color: 'primary'
    },
    {
      icon: 'store',
      label: 'Brands',
      link: '/brands',
      color: 'teal'
    },
    {
      icon: 'menu_book',
      label: 'Magazines',
      link: '/magazines',
      color: 'purple'
    },
    {
      icon: 'picture_as_pdf',
      label: 'Create PDF',
      link: '/create-pdf',
      color: 'red'
    }
  ]
}
