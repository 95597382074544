// import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import { createUploadLink } from 'apollo-upload-client'

const GRAPHQL_ENDPOINT = `//${process.env.DOMAIN_API}/graphql`

const uploadLink = createUploadLink({ uri: GRAPHQL_ENDPOINT })

// const httpLink = new HttpLink({ uri: GRAPHQL_ENDPOINT })

const logoutLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      alert(message)
      // @ts-ignore
      window.$nuxt.$sentry.captureException(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    })
  }

  if (networkError && (<any>networkError).statusCode === 401) {
    window.location.href = '/logout'
  }
})

export default () => ({
  link: logoutLink.concat(uploadLink),
  cache: new InMemoryCache(),
  apollo: {
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        // fetchPolicy: 'network-only',
        errorPolicy: 'ignore'
      },
      query: {
        fetchPolicy: 'no-cache',
        // fetchPolicy: 'network-only',
        errorPolicy: 'all'
      },
      mutate: {
        errorPolicy: 'all'
      }
    }
  },
  defaultHttpLink: false,
  connectToDevTools: true
})
