import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2a142ca3 = () => interopDefault(import('../pages/brands.vue' /* webpackChunkName: "pages/brands" */))
const _4cd34578 = () => interopDefault(import('../pages/create-pdf.vue' /* webpackChunkName: "pages/create-pdf" */))
const _350ae762 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _8d39e53e = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _5f9bfbd8 = () => interopDefault(import('../pages/magazines.vue' /* webpackChunkName: "pages/magazines" */))
const _4fe58c63 = () => interopDefault(import('../pages/releases.vue' /* webpackChunkName: "pages/releases" */))
const _9e65c2fe = () => interopDefault(import('../pages/users.vue' /* webpackChunkName: "pages/users" */))
const _6b63444b = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/brands",
    component: _2a142ca3,
    name: "brands"
  }, {
    path: "/create-pdf",
    component: _4cd34578,
    name: "create-pdf"
  }, {
    path: "/login",
    component: _350ae762,
    name: "login"
  }, {
    path: "/logout",
    component: _8d39e53e,
    name: "logout"
  }, {
    path: "/magazines",
    component: _5f9bfbd8,
    name: "magazines"
  }, {
    path: "/releases",
    component: _4fe58c63,
    name: "releases"
  }, {
    path: "/users",
    component: _9e65c2fe,
    name: "users"
  }, {
    path: "/",
    component: _6b63444b,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
