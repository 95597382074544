






























































































































import { Vue } from 'nuxt-property-decorator'
import Component from 'nuxt-class-component'
import { State } from 'vuex-class'
import { User } from '~/apollo/schema'
import gqlUserUpdateMe from '~/apollo/mutations/UserUpdateMe.graphql'

@Component
export default class MenuMyAccount extends Vue {
  @State('me') me

  editedMe: User = {
    id: -1,
    firstname: '',
    lastname: '',
    email: '',
    roles: []
  }

  dialogMe: boolean = false
  isSavingMe: boolean = false

  $apollo: any
  formRules: any

  async mounted() {
    await this.$store.dispatch('fetchMe', { forceFetch: true })
  }

  editMe() {
    this.editedMe = Object.assign({}, this.me)
    this.dialogMe = true
  }

  closeDialogMe() {
    this.dialogMe = false
    setTimeout(() => {
      this.editedMe = Object.assign({}, this.me)
    }, 300)
  }

  get canSaveMe() {
    if (this.isSavingMe) {
      return false
    }
    const requiredFields = ['firstname', 'lastname', 'email']
    let can = true
    requiredFields.forEach((field) => {
      if (!can) {
        return 0
      } else if (
        this.editedMe[field] === '' ||
        this.editedMe[field].length === 0
      ) {
        can = false
      }
    })
    if (can) {
      can = this.formRules.email(this.editedMe.email) === true
    }
    return can
  }

  async saveMe() {
    if (this.isSavingMe) {
      return
    }
    this.isSavingMe = true
    try {
      await this.$apollo.mutate({
        mutation: gqlUserUpdateMe,
        variables: {
          id: this.editedMe.id,
          email: this.editedMe.email,
          firstname: this.editedMe.firstname,
          lastname: this.editedMe.lastname,
          password: Object.prototype.hasOwnProperty.call(
            this.editedMe,
            'password'
          )
            ? this.editedMe.password
            : ''
        }
      })
      await this.$store.dispatch('fetchMe', { forceFetch: true })
    } catch (e) {}

    this.isSavingMe = false
    this.closeDialogMe()
  }
}
