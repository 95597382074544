









import { Vue, Prop } from 'nuxt-property-decorator'
import Component from 'nuxt-class-component'

@Component
export default class Logo extends Vue {
  @Prop({
    type: String,
    required: true
  })
  width!: string
}
