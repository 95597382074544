const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.ts')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['authenticatedAsAdmin'] = require('../middleware/authenticatedAsAdmin.ts')
middleware['authenticatedAsAdmin'] = middleware['authenticatedAsAdmin'].default || middleware['authenticatedAsAdmin']

middleware['notAuthenticated'] = require('../middleware/notAuthenticated.ts')
middleware['notAuthenticated'] = middleware['notAuthenticated'].default || middleware['notAuthenticated']

export default middleware
