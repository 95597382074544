import { ActionTree, MutationTree } from 'vuex'
import { RootState } from '~/types'
import gqlMe from '~/apollo/queries/Me.graphql'

export const state = (): RootState => ({
  me: undefined
})

export const actions: ActionTree<any, RootState> = {
  async loginLogout({ commit }) {
    await commit('setMe', undefined)
  },
  async fetchMe({ commit }, { forceFetch }) {
    if (this.state.me === undefined || forceFetch === true) {
      const context: any = this
      const QueryResultMe = await context.app.apolloProvider.defaultClient.query(
        {
          query: gqlMe
        }
      )
      commit('setMe', QueryResultMe.data.me)
    }
  }
}

export const mutations: MutationTree<RootState> = {
  setMe(state: RootState, value: any): void {
    state.me = value
  }
}
