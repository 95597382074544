
import JsonToCsv from 'vue-json-to-csv/dist/vue-json-to-csv'
import { Base64 } from 'js-base64'
import { Vue } from 'nuxt-property-decorator'
import Component from 'nuxt-class-component'

@Component({
  extends: JsonToCsv,
  methods: {
    $_downloadCsv(uid, csv, title) {
      try {
        const uri = 'data:text/csv;charset=utf-8;base64,' + Base64.encode(csv)
        const document: any = window.document
        const link: any = document.createElement('a')

        link.id = 'csv-' + uid
        link.href = uri

        document.body.appendChild(link)

        document.getElementById(link.id).style.visibility = 'hidden'
        document
          .getElementById(link.id)
          .setAttribute('download', `${title}.csv`)

        document.body.appendChild(link)
        document.getElementById(link.id).click()

        setTimeout(function() {
          document.body.removeChild(link)
        })
        return true
      } catch (err) {
        return false
      }
    }
  }
})
export default class JsonToCsvFixed extends Vue {}
